import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "gatsby-link"

import Fade from "react-reveal/Fade"
import Link from "../../utils/link"

import DownloadIcon from "../../assets/images/Vectors/Download.svg"
import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import PlusIcon from "../../assets/images/Vectors/Plus.svg"
import MinusIcon from "../../assets/images/Vectors/minus.svg"
import "./CareersAsideWithList.scss"

const LinkComponent = ({ content }) => {
  const pathname = typeof window !== "undefined" ? location.pathname : ""

  const { asideListData } = content

  useEffect(() => {
    const position = parseInt(sessionStorage.getItem("position"))
    if (position) {
      window.scrollTo(0, position)
      sessionStorage.removeItem("position")
    }
  }, [])

  const careerPopup = (e, career) => {
    e.preventDefault()
    sessionStorage.setItem("positionInitial", window.scrollY)
    sessionStorage.setItem("prevLocation", pathname)
    navigate("/careers" + career, { state: { pathname } }, { replace: true })
  }

  return (
    <div className="link__wrapper">
      <Fade>
        <div className="link-component">
          {asideListData.map((link, index) => (
            <div key={index}>
              <Link
                to="#"
                onClick={(e) => careerPopup(e, link.asideItemData.url)}
                className="link-component__link"
              >
                {link.asideItemData.title}
              </Link>
              <Link
                to="#"
                onClick={(e) => careerPopup(e, link.asideItemData.url)}
              >
                <img src={ArrowIcon} className="link-arrow-icon" />
              </Link>
            </div>
          ))}
        </div>
      </Fade>
    </div>
  )
}

const CareersAsideWithList = (props) => {
  const res = useStaticQuery(careerPopupQuery)

  const { asideTitle } = props

  return (
    <>
      <div className="careers-page">
        <div className="statement-section">
          <div className="statement-section__container">
            <div className="download-section__wrapper">
              <div className="download-section__content">
                <Fade>
                  <div className="download-section__description">
                    {asideTitle}
                  </div>
                </Fade>
              </div>
            </div>
            <div className="links-section__wrapper">
              <LinkComponent content={props} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const careerPopupQuery = graphql`
  query {
    allWpCareer {
      edges {
        node {
          title
          slug
          content
          acf {
            fieldGroupName
          }
        }
      }
    }
  }
`

LinkComponent.propTypes = {
  pathname: PropTypes.string,
  content: PropTypes.object,
  asideListData: PropTypes.array,
}

CareersAsideWithList.propTypes = {
  asideImage: PropTypes.object,
  asideParagraph: PropTypes.string,
  asideDownloadLink: PropTypes.object,
  asideListData: PropTypes.array,
}

export default CareersAsideWithList
