import React from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import "../../templates/CaseStudyPopupTemplate.scss"

const CaseStudyPopupAdditionalInfo = (props) => {
  const { information } = props

  return (
    <Fade>
      <article className="caseStudyPopup__infoSecondary">
        {information.map((data, index) => {
          return (
            <p key={index} dangerouslySetInnerHTML={{ __html: data.content }} />
          )
        })}
      </article>
    </Fade>
  )
}

CaseStudyPopupAdditionalInfo.propTypes = {
  props: PropTypes.object,
  information: PropTypes.array,
}

export default CaseStudyPopupAdditionalInfo
