import React from "react"
import PropTypes from "prop-types"

import SectionIntro from "./SectionIntro"

const SectionIntroLayout = ({ content }) => {
  const {
    subHeading,
    subHeadingWithLink,
    subHeadingAlignedToBottom,
    arrowRequiredBelowSubHeading,
    heading,
  } = content

  const introDataWithLink = {
    heading: heading,
  }

  const introDataWithoutLink = {
    title: subHeading,
    heading: heading,
  }

  try {
    introDataWithLink.title = subHeadingWithLink.title
    introDataWithLink.link = subHeadingWithLink.url
  } catch (error) {}

  return (
    <>
      {!!subHeadingWithLink === true ? (
        <SectionIntro
          content={introDataWithLink}
          isTitleBottom={!!subHeadingAlignedToBottom}
          isArrow={!!arrowRequiredBelowSubHeading}
        />
      ) : (
        <SectionIntro
          content={introDataWithoutLink}
          isTitleBottom={!!subHeadingAlignedToBottom}
          isArrow={!!arrowRequiredBelowSubHeading}
        />
      )}
    </>
  )
}

SectionIntroLayout.propTypes = {
  subHeading: PropTypes.string,
  subHeadingWithLink: PropTypes.object,
  subHeadingAlignedToBottom: PropTypes.bool,
  isTitleBottom: PropTypes.bool,
  arrowRequiredBelowSubHeading: PropTypes.bool,
  heading: PropTypes.string,
  introDataWithLink: PropTypes.object,
  introDataWithoutLink: PropTypes.object,
  content: PropTypes.object,
}

export default SectionIntroLayout
