export default function Marquee() {
  const root = document.documentElement
  const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue(
    "--marquee-elements-displayed"
  )
  const marqueeContent = document.querySelector(".logos__marqueeContent")

  root.style.setProperty("--marquee-elements", marqueeContent.children.length)

  for (let i = 0; i <= marqueeElementsDisplayed; i++) {
    marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true))
  }

  const marqueeDesktopElementsDisplayed = getComputedStyle(
    root
  ).getPropertyValue("--marquee-desktop-elements-displayed")
  const marqueeDesktopContent = document.querySelector(
    ".logos__marqueeDesktopContent"
  )

  root.style.setProperty(
    "--marquee-desktop-elements",
    marqueeDesktopContent.children.length
  )

  for (let i = 0; i <= marqueeDesktopElementsDisplayed; i++) {
    marqueeDesktopContent.appendChild(
      marqueeDesktopContent.children[i].cloneNode(true)
    )
  }
}
