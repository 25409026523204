import React, { Fragment } from "react"
import PropTypes from "prop-types"

import "./ImageComponent.scss"

const ImageComponent = ({
  content,
  isFullWidth,
  isContentFullWidth,
  isBanner,
  page,
}) => {
  return (
    <>
      {isContentFullWidth && (
        <Fragment>
          <div
            className={`image content-fullwidth desktop ${page}`}
            style={{ backgroundImage: `url(${content.url})` }}
          >
            <div className="image__container">
              <div className={`image__content ${page}`}>{content.text}</div>
            </div>
          </div>
          <div
            className={`image content-fullwidth mobile ${page}`}
            style={{ backgroundImage: `url(${content.urlMobile})` }}
          >
            <div className="image__container">
              <div className={`image__content ${page}`}>{content.text}</div>
            </div>
          </div>
        </Fragment>
      )}

      {isFullWidth && (
        <div
          className="image fullwidth"
          style={{ backgroundImage: `url(${content.url})` }}
        />
      )}

      {isBanner && (
        <div
          className="image banner"
          style={{ backgroundImage: `url(${content.url})` }}
        />
      )}
    </>
  )
}

ImageComponent.propTypes = {
  content: PropTypes.object,
  isFullWidth: PropTypes.bool,
  isContentFullWidth: PropTypes.bool,
  isBanner: PropTypes.bool,
  page: PropTypes.string,
}

ImageComponent.defaultProps = {
  content: "",
  isFullWidth: false,
  isContentFullWidth: false,
  isBanner: false,
  page: "",
}

export default ImageComponent
