import React from "react"
import PropTypes from "prop-types"

import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import ArrowLeft from "../../assets/images/Vectors/np_arrow-right_888647_FFFFFF.svg"

import "./TextSliderMobile.scss"

const TextSliderMobile = ({ content, sliderOffset, slideRight, slideLeft }) => {
  return (
    <>
      <img className="arrow-left-icon" src={ArrowLeft} onClick={slideLeft} />
      <img className="arrow-right-icon" src={ArrowIcon} onClick={slideRight} />
      <div
        className="text-slider-mobile"
        style={{ transform: `translateX(${sliderOffset}%)` }}
      >
        {content.contentText.map((content, index) => (
          <div key={index} className="text-slider-mobile__columns">
            <div className="text-slider-mobile__column">
              <div className="text-slider-mobile__icons__wrapper"></div>
              <div className="content__column--mobile">
                <div className="content-text">{content.title}</div>
                <div className="content-text">{content.date}</div>
                <div className="content-text">{content.location}</div>
              </div>
            </div>
            <div className="text-slider-mobile__column">
              <div className="paragraph">
                <div className="paragraph-text">{content.description}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

TextSliderMobile.propTypes = {
  content: PropTypes.object,
  sliderOffset: PropTypes.number,
  slideRight: PropTypes.func,
  slideLeft: PropTypes.func,
}

export default TextSliderMobile
