import React, { useState } from "react"
import PropTypes from "prop-types"

import Link from '../../utils/link'

import Fade from "react-reveal/Fade"

import SectionIntroLayout from "../Common/SectionIntroLayout"

import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import "./OurExperts.scss"

const TeamSection = ({ content }) => {
  const { teamMemberData } = content

  const [cardOffset, setCardOffset] = useState(0)

  const previousCard = () => {
    cardOffset === 0
      ? setCardOffset(-100 * (teamMemberData.length - 1))
      : setCardOffset(cardOffset + 100)
  }

  const nextCard = () => {
    cardOffset === -100 * (teamMemberData.length - 1)
      ? setCardOffset(0)
      : setCardOffset(cardOffset - 100)
  }

  return (
    <div className="team-section">
      <div className="team-section__container">
        <Fade>
          <SectionIntroLayout content={content} />
        </Fade>
        <Fade>
          <div className="team-section">
            <div className="team-section__containerAbout">
              <div className="team-section__wrapper">
                {teamMemberData.map((items, index) => (
                  <Link to='/about' key={index} className="team-member">
                    <div className="team-member__container">
                      <Fade>
                        <div className="team-member__image">
                          <img
                            src={items.memberImage.localFile.publicURL}
                            alt="team-member"
                          />
                        </div>
                      </Fade>
                      <Fade>
                        <div className="team-member__name">{items.name}</div>
                        <div className="team-member__role-wrapper">
                          <div className="team-member__role">{items.role}</div>
                          <div className="team-member__arrow-wrapper desktop">
                            <img className="arrow-right-icon" src={ArrowIcon} />
                          </div>
                          <div
                            className="team-member__arrow-wrapper mobile"
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            <img
                              className="arrow-left-icon"
                              src={ArrowIcon}
                              onClick={previousCard}
                            />
                            <img
                              className="arrow-right-icon"
                              src={ArrowIcon}
                              onClick={nextCard}
                            />
                          </div>
                        </div>
                      </Fade>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="team-section__sliderMobile">
                <div className="team-section__slider-container">
                  <div className="team-member">
                    <div
                      className="team-member__container"
                      style={{ transform: `translateX(${cardOffset}%)` }}
                    >
                      {teamMemberData.map((items, index) => (
                        <div key={index} className="team-member__wrapper">
                          <Fade>
                            <div className="team-member__image">
                              <img
                                src={items.memberImage.localFile.publicURL}
                                alt="team-member"
                              />
                            </div>
                          </Fade>
                          <Fade>
                            <div className="team-member__name">
                              {items.name}
                            </div>
                            <div className="team-member__role-wrapper">
                              <div className="team-member__role">
                                {items.role}
                              </div>
                              <div className="team-member__arrow-wrapper desktop">
                                <img
                                  className="arrow-right-icon"
                                  src={ArrowIcon}
                                />
                              </div>
                            </div>
                          </Fade>
                        </div>
                      ))}
                    </div>
                    <div
                      className="team-member__arrow-wrapper--mobile"
                      onClick={(e) => {
                        e.stopPropagation()
                      }}
                    >
                      <img
                        className="arrow-left-icon"
                        src={ArrowIcon}
                        onClick={previousCard}
                      />
                      <img
                        className="arrow-right-icon"
                        src={ArrowIcon}
                        onClick={nextCard}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
        <div className="team-section__divider" />
      </div>
    </div>
  )
}

const OurExperts = (props) => {
  return (
    <div>
      <TeamSection content={props} />
    </div>
  )
}

TeamSection.propTypes = {
  teamMemberData: PropTypes.array,
  content: PropTypes.object,
}

OurExperts.propTypes = {
  teamMemberData: PropTypes.array,
}

export default OurExperts
