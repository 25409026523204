import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"

import Link from "../../utils/link"

import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"

import "./SectionIntro.scss"

const SectionIntro = ({ content, isArrow, isTitleBottom }) => {
  const title = useRef(null)
  useEffect(() => {
    if (isArrow) {
      title.current.style.cursor = "pointer"
    }
  }, [])
  return (
    <div className="section-intro">
      <div className={`title__wrapper ${isTitleBottom ? "bottom" : ""}`}>
        <div ref={title} className="title">
          {isArrow === true ? (
            <Link to={`${content.link}`}>{content.title}</Link>
          ) : (
            content.title
          )}
        </div>
        {isArrow && (
          <Link to={`${content.link}`} className="arrow-icon">
            <img src={ArrowIcon} />
          </Link>
        )}
      </div>
      <div className="heading__wrapper">
        <div
          className="heading"
          dangerouslySetInnerHTML={{ __html: content.heading }}
        ></div>
      </div>
    </div>
  )
}

SectionIntro.propTypes = {
  content: PropTypes.object,
  isArrow: PropTypes.bool,
  isTitleBottom: PropTypes.bool,
}

export default SectionIntro
