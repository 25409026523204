import React, { useState } from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import SectionIntroLayout from "../Common/SectionIntroLayout"
import ImageComponent from "../Common/ImageComponent"
import TextSliderDesktop from "./TextSliderDesktop"
import TextSliderMobile from "./TextSliderMobile"

import "./WhoWeAre.scss"

const WhoWeAre = (props) => {
  const { imageSlider, textSlider, twoColumnInformation } = props

  const textSliderData = {
    contentText: textSlider,
  }

  const [sliderOffset, setSliderOffset] = useState(0)

  const slideRight = () => {
    sliderOffset === -100 * (textSlider.length - 1)
      ? setSliderOffset(0)
      : setSliderOffset(sliderOffset - 100)
  }

  const slideLeft = () => {
    sliderOffset === 0
      ? setSliderOffset(-100 * (textSlider.contentText?.length - 1))
      : setSliderOffset(sliderOffset + 100)
  }

  return (
    <div className="intro-section">
      <div className="intro-section__container">
        <Fade>
          <SectionIntroLayout content={props} />

          <div className="intro-section__image" onClick={slideRight}>
            <div
              className="intro-section__bannerWrapper"
              style={{ transform: `translateX(${sliderOffset}%)` }}
            >
              {imageSlider.map((bannerImage, index) => {
                const banner = {
                  url: bannerImage.backgroundImage.localFile.publicURL,
                }
                return <ImageComponent key={index} content={banner} isBanner />
              })}
            </div>
          </div>
          <div className="intro-section__desktop">
            <TextSliderDesktop
              content={textSliderData}
              sliderOffset={sliderOffset}
              slideRight={slideRight}
              slideLeft={slideLeft}
            />
          </div>
          <div className="intro-section__mobile">
            <TextSliderMobile
              content={textSliderData}
              sliderOffset={sliderOffset}
              slideRight={slideRight}
              slideLeft={slideLeft}
            />
          </div>
          <div className="description">
            <div className="description__columns">
              {twoColumnInformation.map((content, index) => (
                <div key={index} className="description__column">
                  <div
                    className="description-text"
                    dangerouslySetInnerHTML={{
                      __html: content.informationContent,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

WhoWeAre.propTypes = {
  imageSlider: PropTypes.array,
  textSlider: PropTypes.array,
  twoColumnInformation: PropTypes.array,
  props: PropTypes.object,
}

export default WhoWeAre
