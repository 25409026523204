/* eslint-disable react/display-name */
import React from "react"

import Landing from "../components/Common/Landing"
import LandingGallery from "../components/Common/LandingGallery"
import BackgroundImage from "../components/Common/BackgroundImage"
import WhoWeAre from "../components/Home/WhoWeAre"
import HomeOurServices from "../components/Home/HomeOurServices"
import PartnershipsSection from "../components/Home/PartnershipsSection"
import OurExperts from "../components/Home/OurExperts"
import ServicesOurServices from "../components/Services/ServicesOurServices"
import ServicesAsideWithList from "../components/Services/ServicesAsideWithList"
import CareersAsideWithList from "../components/Careers/CareersAsideWithList"
import ServicesPopupDescription from "../components/ServicesPopup/ServicesPopupDescription"
import ServicesPopupBenifits from "../components/ServicesPopup/ServicesPopupBenifits"
import AboutUs from "../components/About/AboutUs"
import OurExpertsAbout from "../components/About/OurExpertsAbout"
import CaseStudyPopupAsideImage from "../components/CaseStudiesPopup/CaseStudyPopupAsideImage"
import CaseStudiesPopupLists from "../components/CaseStudiesPopup/CaseStudiesPopupLists"
import CaseStudyPopupAdditionalInfo from "../components/CaseStudiesPopup/CaseStudyPopupAdditionalInfo"
import CaseStudyLayout from "../components/CaseStudies/CaseStudyLayout"
import LastSection from "../components/Common/LastSection"
import ContactUs from "../components/Contact/ContactUs"

const RenderBlock = (param, el, i, postType) => {
  param = param.replace(postType, "")

  const block = {
    _Acf_ContentBlocks_Landing: (element, index) => {
      return <Landing key={index} {...element} />
    },
    _Acf_ContentBlocks_LandingGallery: (element, index) => {
      return <LandingGallery key={index} {...element} />
    },
    _Acf_ContentBlocks_BackgroundImage: (element, index) => {
      return <BackgroundImage key={index} {...element} />
    },
    _Acf_ContentBlocks_WhoWeAre: (element, index) => {
      return <WhoWeAre key={index} {...element} />
    },
    _Acf_ContentBlocks_HomeOurServices: (element, index) => {
      return <HomeOurServices key={index} {...element} />
    },
    _Acf_ContentBlocks_Partnerships: (element, index) => {
      return <PartnershipsSection key={index} {...element} />
    },
    _Acf_ContentBlocks_OurExperts: (element, index) => {
      return <OurExperts key={index} {...element} />
    },
    _Acf_ContentBlocks_ServicesOurServices: (element, index) => {
      return <ServicesOurServices key={index} {...element} />
    },
    _Acf_ContentBlocks_ServicesAsideWithList: (element, index) => {
      return <ServicesAsideWithList key={index} {...element} />
    },
    _Acf_ContentBlocks_CareersAsideWithList: (element, index) => {
      return <CareersAsideWithList key={index} {...element} />
    },
    _Acf_ContentBlocks_ServicesPopupDescription: (element, index) => {
      return <ServicesPopupDescription key={index} {...element} />
    },
    _Acf_ContentBlocks_ServicesPopupBenifits: (element, index) => {
      return <ServicesPopupBenifits key={index} {...element} />
    },
    _Acf_ContentBlocks_AboutUs: (element, index) => {
      return <AboutUs key={index} {...element} />
    },
    _Acf_ContentBlocks_OurExpertsAbout: (element, index) => {
      return <OurExpertsAbout key={index} {...element} />
    },

    _Acf_ContentBlocks_CaseStudiesList: (element, index) => {
      return <CaseStudyLayout key={index} {...element} />
    },

    _Acf_ContentBlocks_CaseStudyPopupAsideImage: (element, index) => {
      return <CaseStudyPopupAsideImage key={index} {...element} />
    },

    _Acf_ContentBlocks_CaseStudiesPopupList: (element, index) => {
      return <CaseStudiesPopupLists key={index} {...element} />
    },

    _Acf_ContentBlocks_CaseStudyPopupAdditionalDescription: (
      element,
      index
    ) => {
      return <CaseStudyPopupAdditionalInfo key={index} {...element} />
    },

    _Acf_ContentBlocks_LastSection: (element, index) => {
      return <LastSection key={index} {...element} />
    },
    _Acf_ContentBlocks_ContactUs: (element, index) => {
      return <ContactUs key={index} {...element} />
    },
  }[param]

  if (!block) return null
  return block(el, i)
}

export default RenderBlock
