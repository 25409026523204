import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import Fade from "react-reveal/Fade"
import Link from "../../utils/link"

import ImageComponent from "../Common/ImageComponent"
import SectionIntroLayout from "../Common/SectionIntroLayout"

import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import "./HomeOurServices.scss"

const HomeOurServices = (props) => {
  const pathname = typeof window !== "undefined" ? location.pathname : ""

  const {
    bannerImage,
    homeServicesListOne,
    homeServicesListTwo,
    asideInformation,
  } = props

  const bannerData = {
    url: bannerImage.localFile.publicURL,
  }

  useEffect(() => {
    const position = parseInt(sessionStorage.getItem("position"))
    if (position) {
      window.scrollTo(0, position)
      sessionStorage.removeItem("position")
    }
  }, [])

  const servicePopup = (e, service) => {
    e.preventDefault()
    sessionStorage.setItem("positionInitial", window.scrollY)
    sessionStorage.setItem("prevLocation", pathname)
    navigate(service, { state: { pathname } }, { replace: true })
  }

  return (
    <div className="services">
      <div className="services-page">
        <Fade>
          <div className="services-page__section-intro__container">
            <SectionIntroLayout content={props} />
          </div>
        </Fade>
        <Fade>
          <div className="services__image">
            <ImageComponent content={bannerData} isBanner />
          </div>
        </Fade>
        <div className="services__text-container">
          <Fade>
            <div className="description">{asideInformation}</div>
          </Fade>
          <div className="services__wrapper">
            <div className="services__column">
              <Fade>
                {homeServicesListOne.map((data, index) => (
                  <div key={index}>
                    <Link
                      to="//"
                      onClick={(e) =>
                        servicePopup(e, data.homeServicesItemData1.url)
                      }
                      className="services__link"
                    >
                      {data.homeServicesItemData1.title}
                    </Link>
                    <Link
                      to="#"
                      onClick={(e) =>
                        servicePopup(e, data.homeServicesItemData1.url)
                      }
                    >
                      <img src={ArrowIcon} />
                    </Link>
                  </div>
                ))}
              </Fade>
            </div>

            <Fade>
              <div className="services__column">
                {homeServicesListTwo.map((data, index) => (
                  <div key={index}>
                    <Link
                      to="#"
                      onClick={(e) =>
                        servicePopup(e, data.homeServicesListItem2.url)
                      }
                      className="services__link"
                    >
                      {data.homeServicesListItem2.title}
                    </Link>
                    <Link
                      to="#"
                      onClick={(e) =>
                        servicePopup(e, data.homeServicesListItem2.url)
                      }
                    >
                      <img src={ArrowIcon} />
                    </Link>
                  </div>
                ))}
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

HomeOurServices.propTypes = {
  bannerImage: PropTypes.object,
  bannerData: PropTypes.object,
  homeServicesListOne: PropTypes.array,
  homeServicesListTwo: PropTypes.array,
  asideInformation: PropTypes.string,
}

export default HomeOurServices
