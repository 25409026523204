import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import SectionIntroLayout from "../Common/SectionIntroLayout"
import Marquee from "../../utils/marquee"

import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import "./PartnershipsSection.scss"

const PartnershipsSection = (props) => {
  const { logoSlider } = props

  let imageCount

  const desktopMarquee = useRef()

  const [logoOffset, setLogoOffset] = useState(0)
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    if (isHovered) {
      desktopMarquee.current.classList.add("logos__pause")
    } else {
      desktopMarquee.current.classList.remove("logos__pause")
      setLogoOffset(0)
    }
  })

  useEffect(() => {
    Marquee()
  }, [imageCount])

  const slideRight = () => {
    logoOffset === -20 * (logoSlider.length - 1)
      ? setLogoOffset(0)
      : setLogoOffset(logoOffset - 20)
  }

  const slideLeft = () => {
    logoOffset === 0
      ? setLogoOffset(-20 * (logoSlider.length - 1))
      : setLogoOffset(logoOffset + 20)
  }

  return (
    <Fade>
      <div className="partnerships">
        <div className="partnerships__container">
          <div className="section-intro__wrapper">
            <SectionIntroLayout content={props} />
            <div className="partnerships__divider" />
          </div>
        </div>
        <div className="partnerships__wrapper">
          <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="icons__wrapper"
          >
            <img
              className="arrow-right-icon"
              src={ArrowIcon}
              onClick={slideRight}
            />
            <img
              className="arrow-left-icon"
              src={ArrowIcon}
              onClick={slideLeft}
            />
          </div>
          <div className="logos">
            <section className="logos__partnersContainer">
              <div className="logos__wrapper">
                <div className="logos__marqueeDesktop ">
                  <ul
                    ref={desktopMarquee}
                    className="logos__marqueeDesktopContent"
                  >
                    {logoSlider.map((partner, index) => {
                      return (
                        <li
                          style={{ transform: `translateX(${logoOffset}vw)` }}
                          key={index}
                          className="logos__logoList"
                        >
                          <img
                            className="logo"
                            src={partner.logoImage.localFile.publicURL}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </section>

            <section className="logos__marqueeWrapper">
              <section className="logos__marquee">
                <div className="logos__animation">
                  <div className="logos__wrapperMobile">
                    <ul className="logos__marqueeContent">
                      {logoSlider.map((logos, index) => {
                        imageCount = index
                        return (
                          <li
                            key={index}
                            className="logos__logoMobileContainer"
                          >
                            <img
                              className="logoMobile"
                              src={logos.logoImage.localFile.publicURL}
                            />
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      </div>
    </Fade>
  )
}

PartnershipsSection.propTypes = {
  logoSlider: PropTypes.array,
}

export default PartnershipsSection
