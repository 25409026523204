import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Fade from "react-reveal/Fade"
import Link from "../../utils/link"

import ImageComponent from "../Common/ImageComponent"
import Divider from "../Common/Divider"

import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import "./CaseStudyLayout.scss"

const LargeCard = ({ dataLarge }) => {
  const pathname = typeof window !== "undefined" ? location.pathname : ""

  const caseStudyPopup = (e, caseStudy) => {
    e.preventDefault()
    sessionStorage.setItem("positionInitial", window.scrollY)
    sessionStorage.setItem("previousPagePath", pathname)

    navigate(
      "/case-studies" + caseStudy,
      { state: { pathname } },
      { replace: true }
    )
  }

  const {
    heading,
    subHeading,
    backgroundImage,
    viewProject,
    statisticsData,
    description,
  } = dataLarge

  const imageData = {
    url: backgroundImage?.localFile?.publicURL,
  }

  return (
    <div className="large-card__wrapper">
      <Fade>
        <div className="heading__wrapper">
          <div className="property__wrapper">
            <div
              className="property-text"
              dangerouslySetInnerHTML={{ __html: subHeading }}
            />
          </div>
          <div className="location__wrapper">
            <div
              className="location-text"
              dangerouslySetInnerHTML={{ __html: heading }}
            ></div>
          </div>
        </div>
      </Fade>

      <Fade>
        <div className="banner__container">
          <ImageComponent content={imageData} isBanner />
        </div>
      </Fade>
      <div className="text-section__wrapper">
        <Fade>
          <div
            className="title__wrapper desktop"
            onClick={(e) => caseStudyPopup(e, viewProject.url)}
          >
            <div className="title-text">{viewProject.title}</div>
            <div>
              <img
                onClick={(e) => caseStudyPopup(e, viewProject.url)}
                className="case-study__arrow-icon"
                src={ArrowIcon}
              />
            </div>
          </div>
        </Fade>
        <Fade>
          <div className="sales__wrapper">
            {statisticsData.map((data, index) => {
              return (
                <div key={index} className="sales__column">
                  <div className="gross-cost">{data.value}</div>
                  <div className="gross-text">{data.title}</div>
                </div>
              )
            })}
          </div>
        </Fade>
      </div>
      <Fade>
        <div className="large-card__wrapper__divider-wrapper">
          <Divider />
        </div>
      </Fade>

      <div className="large-card__wrapper__description">
        <Fade>
          <div
            className="large-card__wrapper__description-text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Fade>
        <Fade>
          <div className="title__wrapper mobile">
            <Link
              to="#"
              onClick={(e) => caseStudyPopup(e, viewProject.url)}
              className="title-text"
            >
              {viewProject.title}
            </Link>
            <Link to="#" onClick={(e) => caseStudyPopup(e, viewProject.url)}>
              <img
                onClick={(e) => caseStudyPopup(e, viewProject.url)}
                className="case-study__arrow-icon"
                src={ArrowIcon}
              />
            </Link>
          </div>
        </Fade>
      </div>
    </div>
  )
}

const SmallCard = ({ data }) => {
  const { popupLink, cardImage, heading, subHeading } = data

  const pathname = typeof window !== "undefined" ? location.pathname : ""

  const caseStudyPopup = (e, caseStudy) => {
    e.preventDefault()
    sessionStorage.setItem("positionInitial", window.scrollY)
    sessionStorage.setItem("previousPagePath", pathname)
    navigate(
      "/case-studies" + caseStudy,
      { state: { pathname } },
      { replace: true }
    )
  }

  return (
    <div
      onClick={(e) => caseStudyPopup(e, popupLink.url)}
      className="small-card__wrapper"
    >
      <Fade>
        <img src={cardImage?.localFile?.publicURL} className="image" />
      </Fade>
      <Fade>
        <div className="name" dangerouslySetInnerHTML={{ __html: heading }} />
        <div className="small-card__divider">
          <Divider />
        </div>
      </Fade>

      <div className="small-card__text-wrapper">
        <Fade>
          <div
            onClick={(e) => caseStudyPopup(e, popupLink.url)}
            className="property-type"
            dangerouslySetInnerHTML={{ __html: subHeading }}
          />
        </Fade>
        <Fade>
          <Link to="#" onClick={(e) => caseStudyPopup(e, popupLink.url)}>
            <img
              className="case-study__arrow-icon"
              src={ArrowIcon}
              onClick={(e) => caseStudyPopup(e, popupLink.url)}
            />
          </Link>
        </Fade>
      </div>
    </div>
  )
}

const CaseStudyLayout = () => {
  const res = useStaticQuery(caseStudyCardData)

  const cardData = res.wpPage.acf.contentBlocks.filter((el) => {
    return (
      el.fieldGroupName === "page_Acf_ContentBlocks_CaseStudySmallCard" ||
      el.fieldGroupName === "page_Acf_ContentBlocks_CaseStudyLargeCard"
    )
  })

  useEffect(() => {
    const position = parseInt(sessionStorage.getItem("position"))
    if (position) {
      window.scrollTo(0, position)
      sessionStorage.removeItem("position")
    }
  }, [])

  return (
    <>
      <div className="case-study">
        <div className="case-study__container">
          {cardData.map((content, index) => (
            <React.Fragment key={index}>
              {index % 4 === 0 ? (
                <div className="case-study__large-card">
                  <LargeCard dataLarge={content} />
                </div>
              ) : (
                <div className="case-study__small-card">
                  {content.fieldGroupName ===
                    "page_Acf_ContentBlocks_CaseStudySmallCard" && (
                    <SmallCard data={content} />
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  )
}

const caseStudyCardData = graphql`
  query {
    wpPage(title: { eq: "Case Studies" }) {
      acf {
        contentBlocks {
          ... on WpPage_Acf_ContentBlocks_CaseStudyLargeCard {
            fieldGroupName
            heading
            backgroundImage {
              localFile {
                publicURL
              }
            }
            subHeading
            viewProject {
              url
              title
            }
            statisticsData {
              title
              value
            }
            description
          }
          ... on WpPage_Acf_ContentBlocks_CaseStudySmallCard {
            fieldGroupName
            heading
            subHeading
            cardImage {
              localFile {
                publicURL
              }
            }
            popupLink {
              title
              url
            }
          }
        }
        fieldGroupName
      }
    }
    allWpCaseStudy {
      edges {
        node {
          title
          acfCaseStudies {
            apartmentsBuilt
            completionDate
            constructionSize
            description
            fieldGroupName
            firstRoundRetailSpacesBuilt
            grossRealisationValue
          }
        }
      }
    }
  }
`

SmallCard.propTypes = {
  data: PropTypes.object,
  popupLink: PropTypes.object,
  cardImage: PropTypes.object,
  subHeading: PropTypes.string,
  heading: PropTypes.string,
}

LargeCard.propTypes = {
  dataLarge: PropTypes.object,
  additional: PropTypes.array,
  statisticsData: PropTypes.object,
  description: PropTypes.string,
}

export default CaseStudyLayout
