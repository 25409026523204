import React from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import "./LastSection.scss"

const LastSection = (props) => {
  const { lastSectionData } = props

  return (
    <div className="content-image">
      <div className="content-image__container">
        <div className="content">
          <Fade>
            <div className="content__title">{lastSectionData.title}</div>
          </Fade>
          {lastSectionData.backgroundImageMobile && (
            <Fade>
              <div
                className="image--mobile"
                style={{
                  backgroundImage: `url(${lastSectionData.backgroundImageMobile.localFile.publicURL})`,
                }}
              ></div>
            </Fade>
          )}
          {!lastSectionData.backgroundImageMobile && (
            <Fade>
              <div
                className="image--mobile"
                style={{
                  backgroundImage: `url(${lastSectionData.backgroundImage.localFile.publicURL})`,
                }}
              ></div>
            </Fade>
          )}
          <div className="content__wrapper">
            <Fade>
              <div className="content__description">
                {lastSectionData.subTitle}
              </div>
              <div className="content__divider" />

              <div className="content__sub-text">
                {lastSectionData.description}
              </div>
            </Fade>
          </div>
        </div>
        <Fade>
          <div
            className="image--desktop"
            style={{
              backgroundImage: `url(${lastSectionData.backgroundImage.localFile.publicURL})`,
            }}
          ></div>
        </Fade>
      </div>
    </div>
  )
}

LastSection.propTypes = {
  lastSectionData: PropTypes.object,
}

export default LastSection
