import React from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import "../../templates/CaseStudyPopupTemplate.scss"

const CaseStudyAsideImage = (props) => {
  const { rightAlignedImage } = props

  return (
    <Fade>
      <img
        className="caseStudyPopup__asideImage"
        src={rightAlignedImage?.localFile?.publicURL}
        alt="asideImage"
      />
    </Fade>
  )
}

CaseStudyAsideImage.propTypes = {
  props: PropTypes.object,
  rightAlignedImage: PropTypes.object,
}

export default CaseStudyAsideImage
