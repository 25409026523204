import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"

import "./LandingGallery.scss"

import ImageComponent from "./ImageComponent"

const LandingGallery = (props) => {
  const { images, heading } = props

  const [sliderOffset, setSliderOffset] = useState(0)

  const slideRight = () => {
    sliderOffset === -100 * (images.length - 1)
      ? setSliderOffset(0)
      : setSliderOffset(sliderOffset - 100)
  }

  const slideLeft = () => {
    sliderOffset === 0
      ? setSliderOffset(-100 * (images.length - 1))
      : setSliderOffset(sliderOffset + 100)
  }

  return (
    <Fragment>
      <div className={`landing-gallery content-fullwidth`}>
        <div className="landing-gallery__image" onClick={slideRight}>
          <div
            className="landing-gallery__bannerWrapper"
            style={{ transform: `translateX(${sliderOffset}%)` }}
          >
            {images.map((bannerImage, index) => {
              const banner = {
                url: bannerImage?.localFile?.publicURL,
              }
              return <ImageComponent key={index} content={banner} isBanner />
            })}
          </div>
        </div>
        <div className={`landing-gallery__container`}>
          <div className={`landing-gallery__content`}>
            { heading }
          </div>
        </div>
      </div>
    </Fragment>
  )

}

LandingGallery.propTypes = {
  props: PropTypes.object,
  images: PropTypes.array,
  heading: PropTypes.string,
}

export default LandingGallery
