import React from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import "../../templates/ServicesPopupTemplate.scss"

const ServicesPopupBenifits = (props) => {
  const { benifitsContainer } = props

  return (
    <div className="popup__benifitsContainer">
      <Fade bottom>
        <h3 className="popup__benifitsTitle">Benefits include:</h3>
      </Fade>
      <Fade>
        <ul className="popup__benifits">
          {benifitsContainer.map((data, index) => {
            return (
              <li key={index} className="popup__items">
                {data.benifit}
              </li>
            )
          })}
        </ul>
      </Fade>
    </div>
  )
}

ServicesPopupBenifits.propTypes = {
  props: PropTypes.object,
  benifitsContainer: PropTypes.array,
}

export default ServicesPopupBenifits
