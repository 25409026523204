import React from "react"
import PropTypes from "prop-types"

import ImageComponent from "./ImageComponent"

const Landing = (props) => {
  const { backgroundImage, backgroundImageMobile, heading, s3video } = props

  const landingData = {
    url: backgroundImage?.localFile?.publicURL,
    urlMobile: backgroundImageMobile?.localFile?.publicURL,
    text: heading,
  }

  if (s3video) {
    return <video className='landing__video' src={s3video} autoPlay loop muted playsInline />
  } else {
    return <ImageComponent content={landingData} isContentFullWidth />
  }

}

Landing.propTypes = {
  props: PropTypes.object,
  backgroundImage: PropTypes.object,
  backgroundImageMobile: PropTypes.object,
  heading: PropTypes.string,
  s3video: PropTypes.string,
  landingData: PropTypes.object,
}

export default Landing
