import React, { useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

import Fade from "react-reveal/Fade"
import Link from "../../utils/link"

import SectionIntroLayout from "../Common/SectionIntroLayout"

import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import "./AboutUs.scss"
import "./OurExpertsAbout.scss"

const TeamSection = ({ content }) => {
  const { memberCards } = content

  const [cardOffset, setCardOffset] = useState(0)

  const previousCard = () => {
    cardOffset === 0
      ? setCardOffset(-100 * (memberCards.length - 1))
      : setCardOffset(cardOffset + 100)
  }

  const nextCard = () => {
    cardOffset === -100 * (memberCards.length - 1)
      ? setCardOffset(0)
      : setCardOffset(cardOffset - 100)
  }

  const path = typeof window !== "undefined" ? location.path : ""

  const redirectExperts = (e) => {
    e.preventDefault()
    navigate("/about/", { state: { path } }, { replace: true })
  }

  return (
    <Fade>
      <div className="team-section">
        <div className="team-section__containerAbout">
          <div className="team-section__wrapper">
            {memberCards.map((items, index) => (
              <div key={index} className="team-member">
                <div
                  onClick={redirectExperts}
                  className="team-member__container"
                >
                  <Fade>
                    <div className="team-member__image">
                      <img
                        src={items.memberImage.localFile.publicURL}
                        alt="team-member"
                      />
                    </div>
                  </Fade>
                  <Fade>
                    <div className="team-member__name">{items.name}</div>
                    <div className="team-member__role-wrapper">
                      <div className="team-member__role">{items.role}</div>
                      <div className="team-member__arrow-wrapper desktop">
                        <img className="arrow-right-icon" src={ArrowIcon} />
                      </div>
                      <div
                        className="team-member__arrow-wrapper mobile"
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                      >
                        <img
                          className="arrow-left-icon"
                          src={ArrowIcon}
                          onClick={previousCard}
                        />
                        <img
                          className="arrow-right-icon"
                          src={ArrowIcon}
                          onClick={nextCard}
                        />
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            ))}
          </div>
          <div className="team-section__sliderMobile">
            <div className="team-section__slider-container">
              <div className="team-member">
                <div
                  onClick={redirectExperts}
                  className="team-member__container"
                  style={{ transform: `translateX(${cardOffset}%)` }}
                >
                  {memberCards.map((items, index) => (
                    <div key={index} className="team-member__wrapper">
                      <Fade>
                        <div className="team-member__image">
                          <img
                            src={items.memberImage.localFile.publicURL}
                            alt="team-member"
                          />
                        </div>
                      </Fade>
                      <Fade>
                        <div className="team-member__name">{items.name}</div>
                        <div className="team-member__role-wrapper">
                          <div className="team-member__role">{items.role}</div>
                          <div className="team-member__arrow-wrapper desktop">
                            <img className="arrow-right-icon" src={ArrowIcon} />
                          </div>
                        </div>
                      </Fade>
                    </div>
                  ))}
                </div>
                <div
                  className="team-member__arrow-wrapper--mobile"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <img
                    className="arrow-left-icon"
                    src={ArrowIcon}
                    onClick={previousCard}
                  />
                  <img
                    className="arrow-right-icon"
                    src={ArrowIcon}
                    onClick={nextCard}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="team-section__divider" />
        </div>
      </div>
    </Fade>
  )
}

const OurExpertsAbout = (props) => {
  const { expertData } = props

  return (
    <div>
      <div className="about__experts about__container">
        <Fade>
          <div className="about__expertIntro">
            <SectionIntroLayout content={props} />
          </div>
        </Fade>
        <Fade>
          <div className={`about__expertsContainer about__container2 ${expertData.length > 2 ? 'about__expertsContainer--three' : ''}`}>
            {expertData.map((expert, index) => {
              return (
                <div key={index} className="about__expertContent">
                  <img
                    loading="lazy"
                    className="about__expertsContainer__expertImage"
                    src={expert.profileImage.localFile.publicURL}
                    alt={expert.name}
                  />
                  <Fade bottom>
                    <p className="about__expertsContainer__expertName">
                      {expert.name}
                    </p>
                  </Fade>
                  <Fade bottom>
                    <h2 className="about__expertsContainer__expertRole">
                      {expert.role}
                    </h2>
                  </Fade>
                </div>
              )
            })}
          </div>
        </Fade>
        <Fade>
          <div className="about__container2">
            <div className="about__separator3"></div>
          </div>
        </Fade>
        <Fade>
          <div className={`about__expertsContainer about__container2 ${expertData.length > 2 ? 'about__expertsContainer--three' : ''}`}>
            {expertData.map((expert, index) => {
              return (
                <section className="about__expertInfoContainer" key={index}>
                  <div className="about__expertsContainer__separator"></div>
                  <article
                    className="about__expertsContainer__expertDetails"
                    dangerouslySetInnerHTML={{ __html: expert.details }}
                  />
                </section>
              )
            })}
          </div>
        </Fade>
        <Fade>
          <div className={`about__expertsContainer about__container2 ${expertData.length > 2 ? 'about__expertsContainer--three' : ''}`}>
            {expertData.map((expert, index) => {
              return (
                <p key={index} className="about__emailWrapper">
                  <Link
                    className="about__expertsContainer__expertEmail"
                    to={`mailto:${expert.email}`}
                  >
                    {expert.email}
                  </Link>
                </p>
              )
            })}
          </div>
        </Fade>

        <div className="about__expertContainerMobile about__container2">
          {expertData.map((expert, index) => {
            return (
              <div key={index} className="about__expertContainerMobile">
                <div className="about__expertContent">
                  <Fade>
                    <img
                      loading="lazy"
                      className="about__expertsContainer__expertImage"
                      src={expert.profileImage.localFile.publicURL}
                      alt={expert.name}
                    />
                  </Fade>
                  <Fade>
                    <p className="about__expertsContainer__expertName">
                      {expert.name}
                    </p>
                    <h2 className="about__expertsContainer__expertRole about__expertContainerMobile__expertRole">
                      {expert.role}
                    </h2>
                  </Fade>
                  <Fade>
                    <article
                      className="about__expertsContainer__expertDetails about__expertContainerMobile__expertDetails"
                      dangerouslySetInnerHTML={{ __html: expert.details }}
                    />
                    <p
                      key={expert.id}
                      className="about__emailWrapper about__expertContainerMobile__emailWrapper"
                    >
                      <Link
                        className="about__expertsContainer__expertEmail "
                        to={`mailto:${expert.email}`}
                      >
                        {expert.email}
                      </Link>
                    </p>
                  </Fade>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

TeamSection.propTypes = {
  teamMemberData: PropTypes.array,
  content: PropTypes.object,
}

OurExpertsAbout.propTypes = {
  expertData: PropTypes.array,
  props: PropTypes.object,
}

export default OurExpertsAbout
