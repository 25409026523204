import React, {useState, useRef} from "react"
import PropTypes from "prop-types"
import SEO from "../seo"

import Fade from "react-reveal/Fade"

import Link from "../../utils/link"
import Map from "../Common/map"

import ArrowSvg from "../../assets/images/np_arrow-right_888647_FFFFFF-1.svg"
import "./ContactUs.scss"

const Form = ({ id }) => {
  const formEl = useRef(null);
  const [nonEmpty, setNonEmpty] = useState(true)
  const [result, setResult] = useState(false)
  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    enquiry: '',
    newsletter: true
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const checkValidity = (e) => {
    e.preventDefault()

    if (state.name === "" || state.email === "" || state.enquiry === "") {
      setNonEmpty(false)
    } else {
      setNonEmpty(true)
      if (nonEmpty) {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": 'contact',
            ...state,
          }),
        })
          .then(() => {
            setResult(true)
          })
          .catch(error => setResult(false))
      }
    }
  }

  let formProps = {
    ref: formEl,
    name: "contact",
    className: "contact__form",
    onSubmit: checkValidity,
    "data-netlify": "true",
    "data-netlify-honeypot": "bot-field",
  }

  if ( result ) {
    return (
      <p className='contact__success'>Thank you for your enquiry, a member of our team will be in touch shortly.</p>
    )
  } else {
    return (
      <form
        {...formProps}
      >
        <input type="hidden" name="form-name" value="contact" />

        <input
          className="contact__input"
          type="text"
          name="name"
          placeholder="Your Name"
          required
          onChange={(e) => setState({
            ...state,
            name: e.target.value
          })}
        />

        <input
          className="contact__input"
          type="email"
          name="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          title="Invalid email address include . in email address"
          placeholder="Your Email"
          required
          onChange={(e) => setState({
            ...state,
            email: e.target.value
          })}
        />

        <input
          className="contact__input"
          type="text"
          name="phone"
          placeholder="Contact Number"
          required
          onChange={(e) => setState({
            ...state,
            phone: e.target.value
          })}
        />

        <label className="contact__textAreaLabel" htmlFor={`enquiry_${id}`}>
          Your Enquiry
        </label>
        <textarea
          className="contact__textArea"
          name="enquiry"
          id={`enquiry_${id}`}
          cols="30"
          rows="6"
          required
          onChange={(e) => setState({
            ...state,
            enquiry: e.target.value
          })}
        ></textarea>

        <div className='contact__checkbox'>
          <input
            type="checkbox"
            name="newsletter"
            id={`newsletter_${id}`}
            checked={state.newsletter}
            onChange={(e) => setState({
              ...state,
              newsletter: e.target.checked
            })}
          />
          <label htmlFor={`newsletter_${id}`}>Sign me up to your newsletter</label>
        </div>

        <div className="contact__submit">
          <button className="contact__button" type="submit">
            SUBMIT
            <img
              className="contact__arrowImage2"
              src={ArrowSvg}
              alt="arrow-right"
            />
          </button>
        </div>
      </form>
    )
  }
}

const ContactUs = (props) => {
  const {
    heading,
    subHeading,
    bookMeeting,
    bookMeetingLink,
    additionalInformation,
    contactNumber,
    contactEmail,
    socialDetails,
    officeAddressDetails,
    postalAddressDetails,
    mapCoordinates,
  } = props

  return (
    <>
      <SEO title="Contact Us" path="/contact">
      </SEO>
      <div className="contact">
        <Fade>
          <div className="contact__container">
            <section className="contact__header">
              <h1 className="contact__title">{subHeading}</h1>
              <p className="contact__heading">{heading}</p>
            </section>
          </div>
        </Fade>

        <Fade>
          <div className="contact__separatorLine contact__container"></div>
        </Fade>

        <div className="contact__container">
          <section className="contact__details">
            { bookMeetingLink &&
              <Fade>
                <div className="contact__details__meeting">
                  <Link to={bookMeetingLink} className="contact__button">
                    {bookMeeting}
                  </Link>
                  <img
                    className="contact__arrowImage1"
                    src={ArrowSvg}
                    alt="arrow-right"
                  />
                </div>
              </Fade>
            }

            <section className="contact__additional">
              <Fade>
                <p className="contact__additionalInfo" dangerouslySetInnerHTML={{__html: additionalInformation}} />
              </Fade>
              <div className="contact__details__info">
                <Fade>
                  <div className="contact__details__primary">
                    <Link
                      to={`tel:${contactNumber}`}
                      className="contact__details__primary__number"
                    >
                      {contactNumber}
                    </Link>
                    <Link
                      to={`mailto:${contactEmail}`}
                      className="contact__details__primary__email"
                    >
                      Email
                    </Link>
                    {socialDetails.map((social, key) => {
                      return (
                        <Link
                          key={key}
                          to={social.socialMediaLink.url}
                          className="contact__details__primary__social"
                        >
                          {social.socialMediaLink.title}
                        </Link>
                      )
                    })}
                  </div>
                </Fade>
                <Fade>
                  <div className="contact__details__secondary">
                    <h2 className="contact__details__secondary__heading1">
                      {officeAddressDetails.title}
                    </h2>
                    <p className="contact__details__secondary__contents">
                      {officeAddressDetails.plot}
                    </p>
                    <p className="contact__details__secondary__contents">
                      {officeAddressDetails.street}
                    </p>
                    <p className="contact__details__secondary__contents">
                      {officeAddressDetails.city}
                    </p>

                    <h3 className="contact__details__secondary__heading2">
                      {postalAddressDetails.title}
                    </h3>
                    <p className="contact__details__secondary__contents">
                      {postalAddressDetails.box}
                    </p>
                    <p className="contact__details__secondary__contents">
                      {postalAddressDetails.city}
                    </p>
                  </div>
                </Fade>
              </div>
            </section>
            <Fade>
              <Form id='desktop' />
            </Fade>
          </section>
          <section className="contact__detailsMobile">
            <Fade>
              <p className="contact__additionalInfo" dangerouslySetInnerHTML={{__html: additionalInformation}} />
            </Fade>
            <Fade>
              <div className="contact__details__meeting">
                <span className="contact__button">{bookMeeting}</span>
                <img
                  className="contact__arrowImage1"
                  src={ArrowSvg}
                  alt="arrow-right"
                />
              </div>
            </Fade>

            <div className="contact__details__info">
              <Fade>
                <div className="contact__details__primary">
                  <Link
                    to={`tel:${contactNumber}`}
                    className="contact__details__primary__number"
                  >
                    {contactNumber}
                  </Link>
                  <Link
                    to={`mailto:${contactEmail}`}
                    className="contact__details__primary__email"
                  >
                    {contactEmail}
                  </Link>
                  {socialDetails.map((social, key) => {
                    return (
                      <Link
                        key={key}
                        to={social.socialMediaLink.url}
                        className="contact__details__primary__social"
                      >
                        {social.socialMediaLink.title}
                      </Link>
                    )
                  })}
                </div>
              </Fade>
              <Fade>
                <div className="contact__details__secondary">
                  <h2 className="contact__details__secondary__heading1">
                    {officeAddressDetails.title}
                  </h2>
                  <p className="contact__details__secondary__contents">
                    {officeAddressDetails.plot}
                  </p>
                  <p className="contact__details__secondary__contents">
                    {officeAddressDetails.street}
                  </p>
                  <p className="contact__details__secondary__contents">
                    {officeAddressDetails.city}
                  </p>

                  <h3 className="contact__details__secondary__heading2">
                    {postalAddressDetails.title}
                  </h3>
                  <p className="contact__details__secondary__contents">
                    {postalAddressDetails.box}
                  </p>
                  <p className="contact__details__secondary__contents">
                    {postalAddressDetails.city}
                  </p>
                </div>
              </Fade>
            </div>
            <Fade>
              <Form id='mobile' />
            </Fade>
          </section>
        </div>

        <div className="contact__container">
          <Fade>
            <section className="contact__map">
              <Map lat={mapCoordinates.latitude} lng={mapCoordinates.longitude} />
            </section>
          </Fade>
        </div>
      </div>
    </>
  )
}

ContactUs.propTypes = {
  props: PropTypes.object,
  heading: PropTypes.string,
  subHeading: PropTypes.string,
  bookMeeting: PropTypes.string,
  additionalInformation: PropTypes.string,
  contactNumber: PropTypes.string,
  contactEmail: PropTypes.string,
  socialDetails: PropTypes.array,
  officeAddressDetails: PropTypes.object,
  postalAddressDetails: PropTypes.object,
  mapCoordinates: PropTypes.object,
}

export default ContactUs
