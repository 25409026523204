import React from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import SectionIntroLayout from "../Common/SectionIntroLayout"
import ImageComponent from "../Common/ImageComponent"

import "./ServicesOurServices.scss"

const ServicesOurServices = (props) => {
  const imageData = {
    url: props.sectionImage?.localFile?.publicURL,
  }

  return (
    <div className="services-page">
      <Fade>
        <div className="services-page__section-intro__container">
          <SectionIntroLayout content={props} />
        </div>
      </Fade>
      <Fade>
        <div
          className="services-page-imageContainer"
          style={{ height: "550px" }}
        >
          <ImageComponent content={imageData} isBanner />
        </div>
      </Fade>
    </div>
  )
}

ServicesOurServices.propTypes = {
  sectionImage: PropTypes.object,
}

export default ServicesOurServices
