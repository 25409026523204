import React from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import "../../templates/ServicesPopupTemplate.scss"

const ServicesPopupDescription = (props) => {
  const { contentContainer } = props

  return (
    <Fade>
      <div className="popup__details">
        {contentContainer.map((data, index) => {
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          )
        })}
      </div>
    </Fade>
  )
}

ServicesPopupDescription.propTypes = {
  props: PropTypes.object,
  contentContainer: PropTypes.array,
}

export default ServicesPopupDescription
