import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "gatsby-link"

import Fade from "react-reveal/Fade"
import Link from "../../utils/link"

import DownloadIcon from "../../assets/images/Vectors/Download.svg"
import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import PlusIcon from "../../assets/images/Vectors/Plus.svg"
import MinusIcon from "../../assets/images/Vectors/minus.svg"
import "./ServicesAsideWithList.scss"

const Collapse = ({ content, servicesPopupData }) => {
  const pathname = typeof window !== "undefined" ? location.pathname : ""

  const [isToggled, setIsToggled] = useState(false)

  const toggleCollapse = () => {
    setIsToggled(!isToggled)
  }

  const servicePopup = (e, service) => {
    e.preventDefault()
    sessionStorage.setItem("positionInitial", window.scrollY)
    sessionStorage.setItem("prevLocation", pathname)
    navigate("/services" + service, { state: { pathname } }, { replace: true })
  }

  return (
    <Fade>
      <div className="collapse">
        <div className="collapse__container">
          <button className="collapse__wrapper" onClick={toggleCollapse}>
            <div className="collapse__text">{content.asideItemData.title}</div>
            <div className={`collapse__icon ${isToggled ? "active" : ""}`}>
              {isToggled ? <img src={MinusIcon} /> : <img src={PlusIcon} />}
            </div>
          </button>
          <div className={`collapse__content ${isToggled ? "active" : ""}`}>
            <Fade>
              <div className="collapse__image">
                <img
                  src={
                    servicesPopupData.node.acfServices.servicePopupImage
                      .localFile.publicURL
                  }
                  loading="lazy"
                  alt={content.asideItemData.title}
                />
              </div>
            </Fade>
            <Fade bottom>
              <div
                className="collapse__description"
                dangerouslySetInnerHTML={{
                  __html:
                    servicesPopupData.node.acfServices
                      .serviceDescriptionForMobile,
                }}
              ></div>
            </Fade>
            <Fade>
              <div className="collapse__link-wrapper">
                <Link
                  to="#"
                  onClick={(e) => servicePopup(e, content.asideItemData.url)}
                  className="collapse__link"
                >
                  KEEP READING
                </Link>
                <Link
                  to="#"
                  onClick={(e) => servicePopup(e, content.asideItemData.url)}
                >
                  <img className="collapse__arrow-icon" src={ArrowIcon} />
                </Link>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </Fade>
  )
}

const LinkComponent = ({ content }) => {
  const pathname = typeof window !== "undefined" ? location.pathname : ""

  const { asideListData } = content

  useEffect(() => {
    const position = parseInt(sessionStorage.getItem("position"))
    if (position) {
      window.scrollTo(0, position)
      sessionStorage.removeItem("position")
    }
  }, [])

  const servicePopup = (e, service) => {
    e.preventDefault()
    sessionStorage.setItem("positionInitial", window.scrollY)
    sessionStorage.setItem("prevLocation", pathname)
    navigate("/services" + service, { state: { pathname } }, { replace: true })
  }

  return (
    <div className="link__wrapper">
      <Fade>
        <div className="link-component">
          {asideListData.map((link, index) => (
            <div key={index}>
              <Link
                to="#"
                onClick={(e) => servicePopup(e, link.asideItemData.url)}
                className="link-component__link"
              >
                {link.asideItemData.title}
              </Link>
              <Link
                to="#"
                onClick={(e) => servicePopup(e, link.asideItemData.url)}
              >
                <img src={ArrowIcon} className="link-arrow-icon" />
              </Link>
            </div>
          ))}
        </div>
      </Fade>
    </div>
  )
}

const ServicesAsideWithList = (props) => {
  const res = useStaticQuery(servicePopupQuery)

  const { asideDownloadLink, asideImage } = props

  return (
    <>
      <div className="services-page">
        <div className="statement-section">
          <div className="statement-section__container">
            <div className="download-section__wrapper">
              <div className="download-section__content">
                { asideImage &&
                  <Fade>
                    <div className="download-section__image">
                      <img
                        src={asideImage?.localFile?.publicURL}
                        alt="aside image"
                        loading="lazy"
                      />
                    </div>
                  </Fade>
                }
                <Fade>
                  <div className="download-section__description">
                    {props.asideParagraph}
                  </div>
                </Fade>
              </div>
              { asideDownloadLink?.localFile?.publicURL &&
                <Fade>
                  <div className="download-section__link-wrapper">
                    <a
                      href={asideDownloadLink?.localFile?.publicURL}
                      className="link-text"
                      download
                    >
                      DOWNLOAD CAPABILITY STATEMENT
                    </a>
                    <a href={asideDownloadLink?.localFile?.publicURL} download>
                      <img src={DownloadIcon} />
                    </a>
                  </div>
                </Fade>
              }
            </div>
            <div className="links-section__wrapper">
              <LinkComponent content={props} />
            </div>
            <div className="collapse-section__wrapper">
              {props.asideListData.map((content, index) => {
                return (
                  <Collapse
                    key={index}
                    content={content}
                    servicesPopupData={res.allWpService.edges[0]}
                  />
                )
              })}
            </div>
            <div className="download-section__wrapperMobile">
              <div className="download-section__descriptionMobile">
                <a href={asideDownloadLink?.localFile?.publicURL} download>
                  DOWNLOAD CAPABILITY STATEMENT
                </a>
              </div>
              <a href={asideDownloadLink?.localFile?.publicURL} download>
                <img src={DownloadIcon} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const servicePopupQuery = graphql`
  query {
    allWpService {
      edges {
        node {
          acfServices {
            servicePopupImage {
              localFile {
                publicURL
              }
            }
            serviceDescriptionForMobile
            pdfDownlaod {
              localFile {
                publicURL
              }
            }
            nextServiceLink {
              title
              url
            }
            fieldGroupName
          }
        }
      }
    }
  }
`

LinkComponent.propTypes = {
  pathname: PropTypes.string,
  content: PropTypes.object,
  asideListData: PropTypes.array,
}

Collapse.propTypes = {
  content: PropTypes.object,
  servicesPopupData: PropTypes.object,
}

ServicesAsideWithList.propTypes = {
  asideImage: PropTypes.object,
  asideParagraph: PropTypes.string,
  asideDownloadLink: PropTypes.object,
  asideListData: PropTypes.array,
}

export default ServicesAsideWithList
