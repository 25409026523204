import React from "react"
import PropTypes from "prop-types"

import ArrowIcon from "../../assets/images/Vectors/Arrow-1.svg"
import ArrowLeft from "../../assets/images/Vectors/np_arrow-right_888647_FFFFFF.svg"

import "./TextSliderDesktop.scss"

const TextSliderDesktop = ({
  content,
  sliderOffset,
  slideRight,
  slideLeft,
}) => {
  return (
    <div className="text-slider-desktop">
      <div className="text-slider-desktop__icons__wrapper">
        <img
          className="arrow-right-icon"
          src={ArrowIcon}
          onClick={slideRight}
        />
        <img className="arrow-left-icon" src={ArrowLeft} onClick={slideLeft} />
      </div>
      <div className="content">
        <div
          className="content__wrapper"
          style={{ transform: `translateX(${sliderOffset}%)` }}
        >
          {content.contentText.map((content, index) => (
            <div key={index} className="content__columns">
              <div className="content__column">
                <div className="content-text">{content.title}</div>
                <div className="content-text">{content.date}</div>
                <div className="content-text">{content.location}</div>
              </div>
              <div className="content__column">
                <div className="content-paragraph">{content.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

TextSliderDesktop.propTypes = {
  content: PropTypes.object,
  sliderOffset: PropTypes.number,
  slideRight: PropTypes.func,
  slideLeft: PropTypes.func,
}

export default TextSliderDesktop
