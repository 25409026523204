import React, { useRef } from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"
import SectionIntroLayout from "../Common/SectionIntroLayout"
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'

import PlayButton from "../../assets/images/Vectors/play.svg"
import "./AboutUs.scss"

const VideoComponent = ({ content }) => {
  const playBtn = useRef()
  const videoPlayer = useRef()
  const videoPlayerMobile = useRef()

  const { video, videoThumbnail, videoThumbnailMobile } = content

  const handlePause = () => {
    playBtn.current.classList.remove("hide")
    playBtn.current.classList.add("show")
  }

  const handlePlay = () => {
    playBtn.current.classList.add("hide")
  }

  const handlePlayback = () => {
    videoPlayer.current.play()
    videoPlayerMobile.current.play()
  }

  return (
    <div className="videoWrapperAbout">
      <video
        onPause={handlePause}
        onPlay={handlePlay}
        loop
        poster={videoThumbnail?.localFile?.publicURL}
        className="videoContainerAbout--desktop"
        ref={videoPlayer}
        controls
      >
        <source src={video} type="video/mp4" />
      </video>

      <img
        loading="lazy"
        onClick={handlePlayback}
        className="playButton"
        src={PlayButton}
        alt="play-button"
        ref={playBtn}
      />
      {!!videoThumbnailMobile && (
        <video
          onPause={handlePause}
          ref={videoPlayerMobile}
          poster={videoThumbnailMobile?.localFile?.publicURL}
          className="videoContainerAbout--mobile"
          controls
        >
          <source src={video} type="video/mp4" />
        </video>
      )}
    </div>
  )
}

const AboutUs = (props) => {
  const { statisticsContainer, informationContainer } = props

  const formatValue = (value) => `${Number(value).toFixed(0)}`

  return (
    <Fade>
      <div className="about">
        <div className="about__container">
          <div className="about__intro">
            <SectionIntroLayout content={props} />
          </div>
          <VideoComponent content={props} />
          <Fade>
            <section className="about__stats about__container2">
              {statisticsContainer.map((statData, index) => {
                return (
                  <article key={index} className="about__statsInfo">
                    <CountUp end={statData.value} decimals={index === 0 && 1} redraw={true}>
                      {({ countUpRef, start }) => (
                        <VisibilitySensor onChange={start} delayedCall>
                          <span className='about__statNumber' ref={countUpRef} />
                        </VisibilitySensor>
                      )}
                    </CountUp>
                    <p className="about__statText">{statData.title}</p>
                  </article>
                )
              })}
            </section>
          </Fade>
          <Fade>
            <div className="about__container2">
              <section className="about__articleContainer ">
                <article
                  className="about__articles"
                  dangerouslySetInnerHTML={{
                    __html: informationContainer.columnOneInformation,
                  }}
                />

                <article
                  className="about__articles"
                  dangerouslySetInnerHTML={{
                    __html: informationContainer.columnTwoInformation,
                  }}
                />
              </section>
            </div>
          </Fade>
        </div>
      </div>
    </Fade>
  )
}

VideoComponent.propTypes = {
  content: PropTypes.object,
}

AboutUs.propTypes = {
  statisticsContainer: PropTypes.array,
  informationContainer: PropTypes.object,
  props: PropTypes.object,
}

export default AboutUs
