import React from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import "../../templates/CaseStudyPopupTemplate.scss"

const CaseStudiesPopupLists = (props) => {
  const { caseStudyPopupListOne, caseStudyPopupListTwo } = props

  return (
    <section className="caseStudyPopup__features">
      <Fade>
        <article className="caseStudyPopup__featureContent">
          {caseStudyPopupListOne.map((feature1, index) => {
            return (
              <p key={index} className="caseStudyPopup__featureData">
                {feature1.caseStudyPopupItem1}
              </p>
            )
          })}
        </article>
      </Fade>
      <Fade>
        <article className="caseStudyPopup__featureContent">
          {caseStudyPopupListTwo.map((feature2, index) => {
            return (
              <p key={index} className="caseStudyPopup__featureData">
                {feature2.caseStudyPopupItem2}
              </p>
            )
          })}
        </article>
      </Fade>
    </section>
  )
}

CaseStudiesPopupLists.propTypes = {
  props: PropTypes.object,
  caseStudyPopupListOne: PropTypes.array,
  caseStudyPopupListTwo: PropTypes.array,
}

export default CaseStudiesPopupLists
