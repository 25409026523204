import React from "react"
import PropTypes from "prop-types"

import Fade from "react-reveal/Fade"

import ImageComponent from "./ImageComponent"

import "./BackgroundImage.scss"

const BackgroundImage = (props) => {
  const { imageData, isImageBanner, isImageFullWidth } = props

  const backgroundImageData = {
    url: imageData.localFile.publicURL,
  }

  return (
    <Fade>
      <>
        {isImageFullWidth && (
          <div className="fullWidth">
            <ImageComponent
              content={backgroundImageData}
              isFullWidth={isImageFullWidth}
            />
          </div>
        )}
        {isImageBanner && (
          <div className="banner-image" style={{ height: "550px" }}>
            <ImageComponent
              content={backgroundImageData}
              isBanner={isImageBanner}
            />
          </div>
        )}
      </>
    </Fade>
  )
}

BackgroundImage.propTypes = {
  imageData: PropTypes.object,
  isImageBanner: PropTypes.bool,
  isImageFullWidth: PropTypes.bool,
}

export default BackgroundImage
